div.is-sticky {
  position: fixed;
  bottom: 0;
  z-index: 2;
  @media screen and (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
}


.mbl-view {
  @media screen and (max-width: 780px) {
    display: none;
  }
  @media screen and (min-width: 1400px) {
    margin-bottom: 20px;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    margin: 0;
  }
}
.footer {
    height: 80px;
    font-size: 20px;
    width: 100%;
    display: flex;
    background-color: black;
    color: #ffff;
    justify-content: space-between;
    p {
        margin-bottom: 10px;
        font-family: "Lato";
    }
    ul{
      margin-bottom: 0;
    }
    .legend {
        display: flex;
        list-style: none;
        padding: 26px 0;
        li {
            margin-left: 50px;
        }
    }
}

.mark {
  padding: 3px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: -2px;
  height: 1px;
  display: inline-block;
}
.incidence-rate {
  width: 35px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-right: 7px;
  background-color: #03BEE7;
}
.target-incidence {
  width: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-right: 5px;
  background-color: #E86019;
  padding-right: 12px;
}

.mortality-rate {
  width: 35px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-right: 7px;
  background-color: #FF5AE4;
}