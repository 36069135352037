.how-works {
    margin-top: 50px;
    margin-bottom: 50px;
    
    .desc {
        margin-top: 35px;
        h3 {
            margin: 0;
            padding-bottom: 30px;
        }
        a {
            color: #E86019;
        }
    }
    p {
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        line-height: 25px;
        color: #242424;
        margin-bottom: 20px;
    }

    li {
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        line-height: 25px;
        color: #242424;
    }

    .btn-back {
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        padding: 24px 0;
        color: #242424;
        cursor: pointer;
        span {
            margin-right: 10px;
            display: inline-block;
            width: 20px;
            height: 15px;
            background: url('../images/back-arrow-good.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 25px 25px;
            background-repeat: no-repeat;
        }
    }


}

.list-style-none {
    list-style-type:none;
    padding-left: 0;
}

.list-style-dash{
    list-style-type:none;
    li:before {
        content: "-";
        position: absolute;
        margin-left: -10px;
    }
} 

.padding-last {
    padding-bottom: 50px;
}