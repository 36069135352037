.line-chart {
  .line-path {
    fill: none;
    stroke-width: 5;
    stroke-linejoin: round;
    stroke-linecap: round;
    mix-blend-mode: multiply;
  }

  .line-path-projection {
    fill: none;
    stroke: #e86019;
    stroke-width: 3;
    stroke-dasharray: 10;
  }

  .line-path-cases {
    fill: none;
    stroke: #03BEE7;
    stroke-width: 3;
  }

  .line-path-cases-projection {
    fill: none;
    stroke: #38c712;
    stroke-width: 3;
  }

  .line-path-deaths {
    fill: none;
    stroke: #ff5ae4;
    stroke-width: 3;
  }

  .line-path-deaths-projection {
    fill: none;
    stroke: #38c712;
    stroke-width: 3;
  }

  .line-path-data {
    fill: none;
    stroke: #53b6fe;
    stroke-width: 3;
  }

  .tick line {
    stroke: #c0c0bb;
  }

  .title {
    font-size: 2.7em;
    fill: #635f5d;
  }

  .axis-y-rect {
    fill: #53b6fe;
  }

  .axis-y-right-rect-cases {
    fill: #e86019;
  }

  .axis-y-right-rect-deaths {
    fill: #fbde0d;
  }

  .y-axis-right {
    line {
      stroke: #fff;
    }
  }

  .selected-year-line {
    stroke: #c0c0bb;
    stroke-width: 1;
  }
}

.x-axis-1 g.tick:nth-child(even) text {
  display: none;
}

.x-axis g.tick:nth-child(odd) text {
  display: none;
}

.line-chart svg {
  background-color: transparent;
}

.focus text {
  font-size: 14px;
}

.tooltip-date, .tooltip-likes {
  font-weight: bold;
}

.tooltip-line-chart circle.offtrack {
  fill: #D43225;
}

.tooltip-line-chart circle.ontrack {
  fill: #78CF5B;
}

.tooltip-line-chart circle.progress {
  fill: #E9B44D;
}
.map-homeview {
  .axis-label {
    fill: #fff;
    font-size: 12px;
    font-family: "lato";
    line-height: 23px;
    @media screen and (min-width:768px) {
      font-size: 17px;
    }
  }
    .line-chart{
      @media screen and (max-width:768px) {
      margin-left: -20px;
      }
      .x-axis,.y-axis {
        .tick text {
          font-size: 12px;
          font-family: "Oswald";
          line-height: 23px;
          fill: #fff;
        }
      }
    }
}


.line-chart-section{
  .line-chart{
    .tick text{
      font-size: 17px;
      fill: #242424;
      line-height: 23px;
      font-family: "Lato";
    }
    .axis-label{
      font-size: 17px;
      font-family: "Lato";
      line-height: 23px;
    }
  }
}

.view-mbl-only {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    color: #fff;
    margin-left: 10vw;
    font-size: 13px;
    font-family: "lato";
    line-height: 16px;
  }
}
.heading-size {
  font-size: 22px;
  @media screen and (min-width: 768px) {
    font-size: 38px;
  }
}

.line-chart{
  .commonwealth{
    .tick text{
      font-size: 16px;
      line-height: 18px;
      fill: #fff
    }
  }
}

.home-bar-block {
  .line-chart{
    @media screen and (max-width:768px) {
      margin-left: -16px;
    }
    .selected-year-line {
      stroke: #c0c0bb;
      stroke-width: 0;
    }
  }
}

.bar-chart{
  .line-chart{
    .axis-label{
      font-size: 13px;
      font-family: "Lato";
      font-weight: 400;
      fill: #000000;
      @media screen and (max-width:768px) {
        font-size: 12px;
      }
    }
  }
}