/** tracker-header styles */

.map-homeview {
    padding: 20px 5px 28px;
    margin-top: 50px;
    @media screen and (min-width:768px) { 
        padding: 55px 20px 60px;
    }
    background: url('../images/double.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    h3 {
        color: #fff;
        @media screen and (min-width:768px){
        margin-left: 50px;
        }
    }
}
.country-tracker-header {
    padding: 20px 20px 28px;
    position: relative;
    z-index: 1;
    @media screen and (min-width:768px) { 
        padding: 15px 20px 25px;
        background-size: 100px 100px,cover;
    }
    background: url('../images/In\ Beta.svg'),url('../images/header-big.png');
    background-repeat: no-repeat,no-repeat;
    background-size: 50px 50px, cover;
    background-position: right top, bottom;
    
    .cta {
        background-color: #E76126;
        position: relative;
        width: 226px;
        border-radius: 0;
        color: #fff;
        font-size: 14px;
        height: 40px;
        padding: 10px;
        outline: none;
        appearance: none;
        border: 0;
        letter-spacing: 1px;
        line-height: 1.2;
        padding-right: 50px;
        margin-top: 20px;
        

        @media screen and (min-width:768px) {
            height: 53px;
            min-width: 323px;
            font-size: 19px;
            padding: 15px 60px 15px 20px;
        }
        &:after {
            content: '';
            background: url('../images/arrow-white.png');
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 7px;
            top: 7px;
            @media screen and (min-width:768px) {
                background-size: 30px 30px;
                right: 10px;
                top: 14px;
            }
        }
        
    }
}
.tracker-header {
    padding: 20px 20px 28px;
    position: relative;
    z-index: 1;
    @media screen and (min-width:768px) { 
        padding: 55px 20px 60px;
        background-size: 120px 120px, cover;
    }
    background: url('../images/In\ Beta.svg'),url('../images/header-big.png');
    background-repeat: no-repeat,repeat;
    background-size:50px 50px, cover;
    background-position: right top, left top;
    
    .cta {
        background-color: #E76126;
        position: relative;
        width: 226px;
        border-radius: 0;
        color: #fff;
        font-size: 14px;
        height: 40px;
        padding: 10px;
        outline: none;
        appearance: none;
        border: 0;
        letter-spacing: 1px;
        line-height: 1.2;
        padding-right: 50px;
        margin-top: 20px;
        overflow: hidden;
        background: linear-gradient(to right, #CF4802, #CF4802 50%, #E76126 50%);
        background-size: 200% 100%;
        background-position: 100%;
        transition: background-position 500ms ease;
        text-decoration: none; // text decorations are clipped in WebKit browsers
        &:hover {
          background-position: 0 100%;
        }

        @media screen and (min-width:768px) {
            height: 53px;
            min-width: 323px;
            font-size: 19px;
            padding: 15px 60px 15px 20px;
        }
        &:after {
            content: '';
            background: url('../images/arrow-white.png');
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 7px;
            top: 7px;
            @media screen and (min-width:768px) {
                background-size: 30px 30px;
                right: 10px;
                top: 14px;
            }
        }
    }
    .hero-title {
        @media screen and (max-width:768px) { 
            padding-bottom: 20px;
            padding-right: 12px;
        }
    }
}
.hero-title {
    color: #fff;
    padding: 0;
    h1 {
        font-size: 23px;
        font-weight: normal;
        line-height: 1.1;
        padding-right: 12px;
        @media screen and (min-width:768px) { 
          font-size: 62px;
          margin-bottom: 23px;
        }
    }
  
    span {
        display: block;
        color: #e86019;
        @media screen and (min-width:768px) { 
            line-height: 1;
        }
    }
  
    .sub-title {
      font-family: 'Lato', sans-serif;
      font-size: 13px;
      line-height: 1.5;
      max-width: 90%;
      margin: 0 auto;
      @media screen and (min-width:768px) {
          font-size: 24px;
          font-weight: normal;
      }
       
    }
  }

  
  
