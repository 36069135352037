.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width:  66px;
    height: 30px;
    background: #03BEE7;
    border-radius: 19.6552px;
    position: relative;
    transition: background-color .2s;
    @media screen and (max-width: 768px) {
      width: 57px;
      height: 27px;
    }
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 4.8px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    transition: all 0.5s ease-in;
    @media screen and (max-width: 768px) {
      top: 3px;
      width: 22px;
      height: 20px;
    }
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  .toggleText {
      display: flex;
  }
  .toggle-cases {
      margin: -7px 10px 0 0;
      font-size: 20px;
      line-height: 2;
  }
  .toggle-deaths {
      margin: -7px 0 0 10px;
      font-size: 20px;
    line-height: 2;
  }
  .bg-toggle {
      background-color: #242424;
      border-top: 26px solid #242424;
      border-bottom: 26px solid #242424;
      border-left: 15px solid #242422;
      border-right: 15px solid #242422;
      padding-right: 15px;
      @media screen and (max-width: 780px) {
        background-color: #242424;
        border: 20px solid #242422;
        padding-right: 0;
        right: 0;
        top: 7px;
        display: inline-block;
      }
  }
