.country-detail{
    top:-10px;
    @media screen and (min-width:768px){
        top: -34px;
    }
    position: relative;
    .texture-background{
        background: url("../images/TextureLight.jpg") center no-repeat;
        background-size: cover;
        margin-left: -15px;
        margin-right: -15px;
        padding: 20px 15px 40px 15px;
        @media screen and (min-width:768px){
            padding: 30px 30px 90px 30px;
        }
        -webkit-mask-image: url("../images/Header_fill.svg");
        mask-image: images(url("../images/Header_fill.svg")), linear-gradient(black);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: cover;
    }
    .country-flag-block {
        padding-top: 10px;
        @media screen and (min-width:768px){
            margin-bottom: 10px;
            margin-left: 31px;
        }
    }

    .back-to-map{
        cursor: pointer;
        color: #242424;
        font-family: 'Lato',sans-serif;
        font-size: 17px;
        background-image: url('../images/back-to-map.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        background-size: auto;
        padding-left: 30px;
        padding-bottom: 1px;
        margin-left: 19px;
        @media screen and (min-width:768px){
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: 52px;
        }
    }

    .flagbox{
        height: 28px;	
        width: 42px;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        @media screen and (min-width:768px){
            width: 98px;
            height: 65px;
            margin-bottom: 3px;
        }
    }

    .country-name{
        height: 30px;	
        width: 190px;	
        color: #000000;	
        font-family: "Oswald";	
        font-size: 32px;	
        line-height: 30px;	
        font-weight: 400;
        display: inline;
        text-transform: uppercase;
        padding: 0px 10px;
        text-decoration: none;
        @media screen and (min-width:768px){
            height: 80px;	
            width: 675px;	
            font-size: 78px;	
            line-height: 80px;	
        }
    }

    .on-track-text {
        height: 20px;	
        width: 100%;	
        color: #000000;	
        font-size: 20px;	
        line-height: 20px;
        text-align: left;	
        @media screen and (max-width:768px){
            margin: 5px 0;
        }
        @media screen and (min-width:768px){
            height: 34px;	
            width: 480px;	
            font-size: 31px;	
            line-height: 34px;	         	
            display: inline;
        }
    }
    .graph-identifier {
        display: flex;	
        @media screen and (max-width:768px){
            justify-content: center;
            margin-top: 35px;
            margin-bottom: 21px;
        }
        @media screen and (min-width:768px){
            float: right;
            margin-top: 10px;
            
        }
        .block-cases {
            height: 13px;	
            width: 13px;	
            background-color: #E76126;
            display: inline-block;
            @media screen and (min-width:768px){
                height: 17px;	
                width: 17px;	
            }
        }
        .block-deaths {
            height: 13px;	
            width: 13px;	
            background-color: #FBDE0D;
            display: inline-block;
            @media screen and (min-width:768px){
                height: 17px;	
                width: 17px;	
            }
        }
        .block-text {
            height: 14px;	
            color: #000000;		
            font-size: 14px;	
            line-height: 14px;
            display: inline-block;
            text-transform: uppercase;            
            padding-left: 2vw;
            @media screen and (min-width:768px){
                height: 18px;	
                font-size: 17px;	
                line-height: 18px;          
                padding-left: 10px;
            }
        }
        .block-separator {
            box-sizing: border-box;	
            height: 22px;	
            width: 1px;	
            border-left: 1px solid rgba(0,0,0,0.5);
            margin-top: -5px;            
            margin-left: 2vw;
            margin-right: 2vw;
            @media screen and (min-width:768px){
                height: 28px;               
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .bar-chart{

        svg{
            width: 100%;
            height: 20px;
            @media screen and (min-width:768px){
                height: 47px;
            }
        }

        .bottom-label{
            .no-malaria{

                @media screen and (min-width:768px){
                    color: #000;
                    font-size: 17px;
                }

            }

            .year{

                @media screen and (min-width:768px){
                    color: #000;
                    font-size: 17px;
                }

            }

            .commitment{
                color: #000;
                @media screen and (min-width:768px){
                    font-size: 17px;
                    width: 185px;
                    line-height: 1;
                }
            }
            hr{
                width: 78px;
                margin-top: -76px;
                @media screen and (min-width:768px){
                    margin-top: -110px;
                    width: 140px;
                }
            }
        } 
    } 

    .right-section{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
      @media screen and (min-width:768px){  
            display: inline-block;
      }    
    }


    .by-numbers-section{
        order: 2;
        text-align: center;
        margin-top:30px;
        div{
            p{
                padding:0;
                margin:0;
                color: #000;
                font-family: "Lato";
                font-style: "normal";
                font-size: 17px;
            }
            .country-detail-no{
                font-size: 67px;
                padding: 0;
            }
            .population-at-risk {
                color: #B3B3B3;
                font-size: 25px;
                font-family: "Oswald";
            }
            .malaria-cases {
                color: #03BEE7;
                font-size: 25px;
                font-family: "Oswald";
            }
            .malaria-deaths {
                color: #FF5AE4;
                font-size: 25px;
                font-family: "Oswald";
            }
            .incidence-rate-detail {
                color:  #03BEE7;
                font-size: 25px;
                font-family: "Oswald";
            }
            .mortality-rate-detail {
                color: #FF5AE4;
                font-size: 25px;
                font-family: "Oswald";
            }
        }
        @media screen and (min-width:768px){
            //display: inline-block;
        }
        .svg-underline {
            height: 2px;	
            width: 280px;	
            opacity: 0.63;	
            transform: scaleX(-1);
            margin-top: -10px;
            @media screen and (min-width:768px){
                height: 3px;	
                width: 532px;	
                opacity: 0.63;
                margin-top: -24px;
            }
        }
        .country-detail-no {
            line-height: 1.2;
        }
        .yoy-change {
            display: inline-block;
            position: relative;
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            div {
                padding-top: 0;
            }

            .yoy-change-arrow-red {
                width: 0; 
                height: 0; 
                margin-top: 5px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 15px solid #f00;
                float: left;
            }

            .yoy-change-percentage-red {
                color: red;
                margin: 0 5px;
            }

            .yoy-change-arrow-green {
                width: 0;
                height: 0; 
                margin-top: 5px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 15px solid #38c712;
                float: left;
            }

            .yoy-change-percentage-green {
                color: #38c712;
                margin: 0 5px;
            }

            .yoy-change-text {
                text-transform: uppercase;
                color: #a8a8a8;
            }
        }
    }
 

    .bar-track {
        @media screen and (min-width:768px){
            width: 100%;
            height: 40px;
        }
    }
    .about-section{
        order: 3;
        margin-top:30px;
        font-family: 'Lato', sans-serif;
        font-size: 17px;
        color: #242424;
        @media screen and (min-width:768px){
            margin-top:30px;
            text-align: center;
        }
        div:first-of-type {
            padding-top: 10px;
        }
        h3 {
            text-align: left;
            font-size: 35px;
            padding-top: 30px;
            font-weight: 400;
            @media screen and (min-width:768px){
            margin: 2px 100px;
            font-size: 62px;
            }
        }
        h3.padding-top-medium{
            padding-top: 116px;
            font-size: 35px;
            @media screen and (min-width:1021px){
                padding-top: 10%;
            }
            @media (min-width:768px) and (max-width:1020px) {
                padding-top: 9%;
            }
        }
        .section-1 {
            .svg-underline {
                height: 5px;	
                width: 200px;	
                opacity: 0.63;	
                transform: scaleX(-1);
                @media screen and (min-width:768px){
                    height: 6px;	
                    width: 343px;	
                    opacity: 0.63;
                    float: left;
                    margin: 0px 100px;
                }
            }
            h5 {
                margin:0 0;
                @media screen and (min-width:768px){
                    float: left;
                    margin: 17px 100px; 
                }
            }
            p {
                @media screen and (min-width:768px){
                    margin: 50px 100px;
                    margin-bottom: 20px;
                }
            }
        }
        .section-2 h4{
            font-size: 26px;
            padding-top: 0;
            margin-bottom: -5px;
            font-weight: 400;
            @media screen and (min-width:768px){
                font-size: 49px;
                text-align: left;
                padding-top: 0;
                padding-left: 100px;
            }
            @media (min-width:768px) and (max-width:1024px){
                padding-top: 17%;
                padding-left: 13%;
            }
        }
        .section-3 h5{
            font-size: 20px;
            margin-bottom: -5px;
            font-weight: 400;
            @media screen and (min-width:768px){
                font-size: 38px;
                text-align: left;
                padding-left: 100px;
            }
            
        }
        .section-3{
            .svg-underline {
                height: 5px;	
                width: 97px;	
                opacity: 0.63;	
                transform: scaleX(-1);
                @media screen and (min-width:768px){
                    height: 6px;	
                    width: 185px;	
                    opacity: 0.63;
                    float: left;
                    margin: 0px 100px;
                }
                @media (min-width:768px) and (max-width:1024px){
                    
                }
            }
        }
        .section-2 {
            a {
                color: #E86019;
            }
            p {
                @media screen and (min-width:768px){
                    margin: 7px 100px;
                }
            }
        }
        p {
            text-align: left;
            padding-top: 10px;
            padding-bottom: 20px;
            @media screen and (min-width:768px){
                margin: 7px 100px;
            }
        }
        button {
            font-family: "Oswald";
            font-size: 19px;
            line-height: 22px;
            background-color: #E76126;
            height: 53px;
            color: #fff;
            padding: 10px 15px;
            border: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            float: left;
            margin: 30px 0px;
            @media screen and (min-width:768px){
                width: 246px;
                margin: 34px 101px;
            }
        }
        img{
            max-width: 100%;
            @media screen and (min-width:1024px){
                width: 964px;
            }
        }
    }

    .line-chart-section{
        margin-top:10px;
        order: 1;
        @media screen and (min-width:768px){
            display: inline-block;
            margin-top:15px;
            float: left;
            padding-left:50px;
         }
         .svg-underline {
            width: 78px;
            height: 6px;
            opacity: 0.63;
            @media screen and (min-width:768px){
              width: 83px;
              height: 6px;
            }

            @media screen and (max-width: 768px) and (min-width: 768px) {
                padding-left: 0;
            }
    

        }
        .cases-line-chart {
            padding-top: 20px;
        }
        .cases-line-chart, .deaths-line-chart{
            h6{
              font-size: 20px; 
                span{
                  color: #9C9C9C;
                  font-size: 17px;
                  padding-left: 10px;
                }
            }
            .malaria-cases-legends, .malaria-death-legends{
               font-family: "lato",sans-serif;
               color: #000;
               margin-top: 20px;
                ul{
                    list-style: none;
                    padding-left: 0;
                    display: inline;
                    float: left;
                     li{
                        font-size: 15px;
                        font-weight: 600;
                      span{
                          height: 5px;
                          width: 30px;
                          display: inline-block;
                          border-radius: 5px;
                          margin-right: 15px;
                      }
                   }
                   .populate{
                    background-color:#53B6FE;
                   }
                   .estimate{
                    background-color:#E86019;
                   }
                   .estimate-deaths{
                    background-color:#EDC224;   
                   }
                   .commitments{
                    background-color:#38C712; 
                   }
                }
               p{
                 float: right;
                 padding-top: 15px;
                 padding-right: 73px;
                 font-size: 13px;
               } 
            }
        }
    }

    h3{
        font-size: 26px;
        font-family: "Oswald",sans-serif;
        margin:0px;
        @media screen and (min-width:768px){
            font-size: 49px;
        }
    }

    .country-navigation{
        margin-left:-15px;
        margin-right:-15px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top:30px;
        font-size: 16px;
        padding: 40px 10px 50px 10px;
        @media (max-width: 768px) {
            display: none;
       }
        background: url("../images/TextureDark.jpg");
        background-position: 160% 50%;
        color:#FFF;
        @media screen and (min-width:768px){
          height: 125px;
        }

        .navigation-container{
            @media screen and (min-width:768px){
                margin: 0 auto;
            }
        .previous{
            background-image: url('../images/back-arrow.svg');  
            background-size: contain;
            background-repeat: no-repeat;
            padding-left:30px; 
            line-height: 1;
            height: 22px;
            margin-left: 68px;
            float: left;
            cursor: pointer;
            @media screen and (min-width:768px){
               font-size: 35px;
               padding-left:50px; 
               height: auto;
            }
            @media (min-width: 768px) and (max-width: 1025px) {
                font-size: 35px;
                padding-left:40px;
           }
        }

        .next{
            background-image: url('../images/next-arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 96% 0%;
            padding-right:30px; 
            line-height: 1;
            height: 22px;
            margin-right: 68px;
            cursor: pointer;
            font-size: 16px;
            @media screen and (min-width:768px){
                font-size: 35px; 
                height: auto;
                background-position: 100% 0%;
                padding-right: 42px;
             }
             @media screen and (min-width: 780px) {
                font-size: 35px;
                padding-right:50px; 
                background-position: 99% 0%;
                float: right;
           }
        }
      } 
    }

    .country-index{
        margin: 0 auto;
        text-align: center;
        position: relative;
        top:-72px;
        color: #FFF;
        width: 20%;
        @media screen and (min-width:768px){
            font-size: 28px;
            top:-85px;
        }
        @media screen and (max-width:768px){
            display: none;
        }
        .current-index, .total-index {
            color: #E86019;
        }
    }
}

.vertical-border {
    border-bottom: 0.8px solid #B3B3B3;
    padding-bottom: 45px;
    @media screen and (min-width:768px){
        border-right: 0.8px solid #B3B3B3;
        border-bottom: none;
    }
}

.number-section-row {
    padding-top: 23px;
    @media screen and (max-width: 780px){
        padding-top: 20px;
    }
}

.number-section-block {
    @media screen and (min-width:768px){
        padding: 13px 0;
        min-height: 148px;
    }
}
.align-middle-desktop {
    padding-left: 25.6%;
    @media screen and (max-width:760px){
        padding-left: 0;
    }
}

.border-bottom-mbl {
    @media screen and (max-width:760px){
        border-bottom: 0.8px solid #B3B3B3;
        padding-bottom: 45px;
        padding-top: 20px;
    }
}

.border-bottom-desk {
    &::after{
        @media screen and (min-width:768px){
            content: '';
            display: block;
            height: 1px;
            background-color: #B3B3B3;
            margin: 60px -50% 0;
        }
    }
    
}

.heading-underline {
    
    background: url('../images/brush-1.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 500px;
    
}
#heading-underline-quarter {
    background: url('../images/brush-1.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 350px;
    width: 212px;
    margin-right: 49%;
    @media screen and (min-width:768px){
        background-size: 556px;
        width: 400px;
        margin-right: 49%;
    }
}
.section-1{
    svg{
        width: 135px;
        height: 7px;
        margin-right: 65%;
        @media screen and (min-width:768px){
            width: 297px;
            height: 7px;
            margin-right: 63%;
        }
    }
}
.section-2{
    svg{
        width: 135px;
        height: 7px;
        margin-right: 65%;
        @media screen and (min-width:768px){
            width: 320px;
            height: 7px;
            margin-right: 56%;
        }
    }
}
.section-3{
    svg{
        width: 110px;
        height: 7px;
        margin-right: 67%;
        @media screen and (min-width:768px) {
            width: 182px;
            height: 7px;
        }
    }
}
  
.loader-leave {
opacity: 1;
}
.loader{
    
opacity: 0.01;
//transition: opacity 0.25s ease-in 0.3s;  
}

.bold-text{
    font-weight: 600;
}