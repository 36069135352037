/* Write our sidewide code here */

//overrides bootstrap container max width

.App {
  font-family: "Oswald", sans-serif;
  transition: opacity .1s ease-in-out;
  position: relative;
  overflow: hidden;

  .container {
    width: 100%;
    max-width: 1200px;

    @media (min-width: 768px) {
      padding: 0 15px;
    }
  }
}

/** worldmap title */

/** headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
}

.heading-1 {
  text-transform: uppercase;
  font-size: 23px;

  @media screen and (min-width: 768px) {
    font-size: 63px;
  }
}

.heading-2 {
  text-transform: uppercase;
  font-family: "Oswald";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 40px;
  }
}

/** heading underline */
.h-underline {
  display: inline-block;
  text-decoration: none;
  background-image: url('./images/brushtwo-full.svg');
  background-repeat: no-repeat;
  background-size: 100% 5px;
  padding-bottom: 10px;
  background-position-y: 100%;
  overflow-y: visible;
}



/** worldmap styles */

.world-map {
  border: 1px solid #808080;
  position: relative;
  overflow: hidden;
  @media screen and (min-width:768px) {
  border-top: none;
  }
  .map-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  canvas {
    display: block;
    width: 100%;
    visibility: hidden;
  }

  .zoom-buttons {
    position: absolute;
    margin-top: -125px;
    margin-left: 10px;
  }
}

.cbyc-progress {
  width: 100%;
  display: block;
}

.region-filter {
  width: 100%;
  display: block;
  padding: 5px;

  .filter {
    display: inline-block;
    padding: 10px;
  }
}

// .cta {
//   background-color: #e86019;
//   border-radius: 0;
//   color: #FFF;
//   font-size: 18px;
//   height: 50px;
//   padding: 14px;
//   outline: none;
//   appearance: none;
//   border: 0;
//   letter-spacing: 1.36px;
//   line-height: 1.2;

//   @media (min-width: 768px) and (max-width: 1025px) {
//     margin-top: 10px;
//   }

//   @media screen and (min-width:768px) {
//     padding: 0px 14px;
//   }
// }

html {
  h3 {
    font-size: 15px;
    font-weight: normal;
    margin: 20px 10vw 10px 10vw;

    @media screen and (min-width:768px) {
      font-size: 30px;
    }
  }

  h2 {
    font-weight: normal
  }

  .body {
    width: 100%;
  }

  // .select-country {
  //   background: url("./images/TextureDark.jpg");
  //   background-position: 160% 50%;
  //   color: #fff;
  //   font-size: 15px;
  //   padding: 20px 40px;
  //   margin-top: 25px;
  //   text-align: center;
  //   width: 100vw;

  //   .o--mobile {
  //     padding: 0px 45px;
  //     display: inline-block;
  //   }

  //   .o--desktop {
  //     display: none;
  //   }

  //   @media screen and (min-width:768px) {
  //     font-size: 25px;
  //     width: 82%;
  //     z-index: 2;
  //     margin: 0 auto;
  //     margin-top: 20px;
  //     padding: 10px 20px;

  //     .o--desktop {
  //       display: inline;
  //     }

  //     .o--mobile {
  //       display: none;
  //     }

  //     p {
  //       display: inline-block;
  //       margin-right: 0%;
  //       margin-bottom: 0px;
  //       padding: 0px 8px;

  //       select {
  //         float: right;
  //       }
  //     }
  //   }

  //   @media screen and (min-width: 1025px) {
  //     p {
  //       margin-right: 6%;
  //     }
  //   }

  //   select::-ms-expand {
  //     display: none;
  //   }

  //   select {
  //     background-color: #e86019;
  //     border-radius: 0;
  //     color: #FFF;
  //     font-size: 18px;
  //     width: 206px;
  //     height: 50px;
  //     padding: 14px;
  //     background: url("./images/down.png") 94% no-repeat #e86019;
  //     background-size: 32px 33px;
  //     outline: none;
  //     -webkit-appearance: none;
  //     -moz-appearance: none;
  //     appearance: none;
  //     border: 0;
  //     letter-spacing: 1.36px;
  //     line-height: 1.2;

  //     @media (min-width: 768px) and (max-width: 1025px) {
  //       margin-top: 10px;
  //     }

  //     @media screen and (min-width:768px) {
  //       padding: 0px 14px;
  //     }
  //   }
  // }

  // .country-box {
  //   height: auto;
  //   float: left;

  //   div {
  //     img {
  //       width: 124px;
  //       height: 74px;
  //       object-fit: cover;

  //       @media screen and (min-width:768px) {
  //         width: 258px;
  //         height: 152px;
  //       }
  //     }
  //   }

  //   .country-box-text,
  //   p {
  //     margin-top: 15px;
  //     color: #000;

  //     h5 {
  //       font-family: 'Lato', sans-serif;
  //       font-size: 14px;
  //       margin-bottom: 20px;
  //       font-weight: 600;

  //       @media screen and (min-width:768px) {
  //         font-size: 24px;
  //         margin: 0;

  //       }
  //     }

  //     span {
  //       font-family: 'Lato', sans-serif;
  //       font-size: 14px;
  //       display: none;
  //       line-height: 18px;
  //       font-weight: 400;

  //       @media screen and (min-width:768px) {
  //         display: inline-block;

  //       }
  //     }
  //   }
  // }

  // .navigation__utility-nav>.menu a {
  //   margin-top: 0.25rem;
  //   outline: none;
  // }

  // .dwn-arrow {
  //   display: inline-block;
  //   width: 40px;
  //   height: 40px;
  //   background: url("./images/Oval.svg") 8px 9px no-repeat #e86019;
  //   background-size: 24px 24px;
  //   bottom: -6px;
  //   right: -12px;
  //   position: relative;
  //   border-radius: 100%;
  // }

  // .lowr-content {
  //   width: 100%;
  //   justify-content: center;
  // }

  // .cool p {
  //   background: url("./images/brushstrone.svg") no-repeat;
  //   background-position: center;
  //   background-size: 400px 10px;
  // }

  // .latest-section {
  //   background: url("./images/TextureLight.jpg");
  //   margin-top: -45px;
  //   padding-top: 15px;

  //   h4 {
  //     font-weight: 400;
  //     font-size: 35px;
  //     margin-top: 10px;

  //     @media screen and (min-width:768px) {
  //       font-size: 40px;
  //       margin-top: 25px;
  //     }
  //   }

  //   .svg_border {
  //     height: 2em;
  //     width: 12em;

  //     @media screen and (min-width:768px) {
  //       height: 2em;
  //       width: 15em;
  //     }
  //   }

  //   .country-action {
  //     margin-top: 10px;

  //     @media screen and (min-width:768px) {
  //       min-width: 100%;
  //       margin-top: 25px;

  //       .lowr-content {
  //         display: flex;
  //         padding-top: 15px;
  //       }
  //     }
  //   }
  // }

  // a:hover {
  //   text-decoration: none;
  // }


  // .hide-block {
  //   display: none;
  // }




  /* basic positioning */
  // .legend {
  //   font-size: 14px;

  //   @media screen and (min-width:768px) {
  //     font-size: 17px;
  //   }

  //   span {
  //     width: 13px;
  //     height: 13px;
  //     display: inline-block;
  //     margin: 0px 5px;

  //     @media screen and (min-width:768px) {
  //       width: 17px;
  //       height: 17px;
  //       margin: -1px 5px;
  //     }
  //   }

  //   hr {
  //     -webkit-transform: rotate(90deg);
  //     transform: rotate(90deg);
  //     width: 17px;
  //     margin-top: 7px;
  //     border-top: 1px solid rgba(0, 0, 0, 0.5);
  //   }

  //   .malaria-cases {
  //     background-color: #e76126;
  //   }

  //   .malaria-deaths {
  //     background-color: #FBDE0D;
  //   }
  // }

  // .source {
  //   margin-top: 20px;
  //   height: 30px;
  //   color: #000000;
  //   font-family: Lato;
  //   font-size: 13px;
  //   line-height: 15px;

  //   @media screen and (min-width:768px) {
  //     margin-top: 20px;
  //     height: 30px;
  //     font-size: 13px;
  //     line-height: 15px;
  //   }
  // }

}


/** background textures and masks */
.texture-dark {
  background: url("./images/TextureDark.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.texture-light {
  background: url("./images/TextureLight.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mask-bottom {
  mask-image: url("./images/Header_fill.svg");
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-size: cover;
}

// .mask-top-bottom {
//   mask-image: url("./images/header-fill-tb.svg");
//   mask-position: bottom;
//   mask-repeat: no-repeat;
//   mask-size: cover;
//   transform: rotate(180deg);
//   transform-origin: center;
// }


// .mask-top-bottom {
//     mask-image: url("../images/Header_fill.svg");
//     mask-position: bottom;
//     mask-repeat: no-repeat;
//     mask-size: cover;
// }