.progress-in-action {
    padding-bottom: 40px;
    padding-top: 30px;

    @media screen and (min-width:768px) {
        padding-bottom: 60px;
        padding-top: 50px;
    }
    
    p {
        font-family: 'Lato', sans-serif;
        line-height: 1.5;
        font-size: 13px;
        margin-bottom: 10px;
        @media screen and (min-width:768px) {
            font-size: 17px;
        }
    }

    .cta {
        background-color: #e86019;
        position: relative;
        
        border-radius: 0;
        color: #fff;
        font-size: 14px;
        height: 40px;
        padding: 10px;
        outline: none;
        appearance: none;
        border: 0;
        letter-spacing: 1px;
        line-height: 1.2;
        padding-right: 50px;

        @media screen and (min-width:768px) {
            height: 53px;
            font-size: 19px;
            padding: 15px 60px 15px 20px;
        }
        &:after {
            content: '';
            background: url('../images/arrow-white.png');
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 7px;
            top: 7px;
            @media screen and (min-width:768px) {
                background-size: 30px 30px;
                right: 10px;
                top: 14px;
            }
        }
    }
}