.region-filter {

    /** custom radio styling */
    input[type='radio'] {
        position: relative;
        left: 7px;
        top: 4px;
        vertical-align: top;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        outline: none;
        border: 2px solid #B7B7B7;
        background-color: #FFFFFF;
    }

    input[type='radio']:checked {
        border-color: #E86019;
        box-shadow: 0 0 9px 0 #E86019;
    }

    /* ms edge hack*/
    input[type='radio']:checked::-ms-check {
        border-color: #E86019;
        -webkit-appearance: none;
        color: #E86019;
        box-shadow: 0 0 9px 0 #E86019;
       border:none;
    }

    input[type="radio"]:checked:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        margin: 3px auto;
        border-radius: 50%;
        background: #E86019;
    }
    
    /** custom radio styling end */

    input[type='radio']:checked + span {
        color: #E86019;
    }

    text-align: center;
    padding: 0;
    height: 58px;
    width: 266px;
    background: #E86019;
    @media screen and (min-width:768px) {
        padding: 17.5px 40px 0px;
        text-align: left;
        height: 68px;
        background: url("../images/v2.jpg") no-repeat;
        background-size: 100% auto;
        margin-left: 20px;
    }

    margin-bottom: 30px;
    font-size: 17px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: cover;

    label {
        padding-left: 22px;
        span {
            padding-left: 4px;
            font-size: 15px;
            line-height: 1.5;
            margin: 0;
            text-transform: uppercase;
            cursor: pointer;
            vertical-align: top;
        }
    }

    input {
        margin-right: 10px;
    }

    .select-region {
        width: 240px;
        height: 60px;
        background: url("../images/down-black.png") 94% 54% no-repeat transparent;
        background-size: 22px 22px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1.36px;
        line-height: 1.2;
        color: #000;
        appearance: none;
        outline: none;
        border: 0;
        border-radius: 0;
        @media screen and (max-width:768px) {
            color: #FFFFFF;
            background: url('../images/dropdown.svg') 98% 50% no-repeat transparent;
        }
    }

}