.partners {
    margin-top: 62px; 
    color: #9C9C9C;
    border-top: 1px solid #DBDBDB;
    @media screen and (min-width:768px){
        margin-top: -62px; 
    }
}

.heading {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    line-height: 1;
    margin: 25px auto;
}

.footer-logo {
    margin-top: 65px;
    @media screen and (min-width:768px){
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

.logos {
    display: grid;
    @media screen and (min-width:768px){
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
    }
}

.logo-img {
    max-width: 100%
}

.logo {
    height: 100px;
    text-align: center;
}
.designed-by {
    padding-top: 60px;
    padding-bottom: 174px;
    @media screen and (min-width:768px){
        padding-top: 0;
    }
    a:hover{
        text-decoration: none;
    }
    span {
        color: #9C9C9C;
        font-size: 13px;
        font-family: Lato;
        line-height: 18px;
        margin-right: 8px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding-bottom: 0.5px;
        text-decoration: none;
    }
    .isobar-logo {
        height: 20px;
    }
    img{
        vertical-align: bottom;
    }
}