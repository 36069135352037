
.flagbox {
    width: 29px;
    height: 22px;
    @media screen and (min-width:1025px) {
      width: 40px;
      height: 30px;
    }
    // @media (min-width: 768px) and (max-width: 1025px) {
    //   width: 29px;
    //   height: 22px;
    // }
    display: inline-block;
  }
  
  .country-name {
    display: inline-block;
    text-transform:uppercase;
    font-size: 22px;
    padding: 0px 10px;
    text-decoration: underline;
    cursor: pointer;
    @media (min-width: 760px) {
      font-size: 14px ;
      padding: 0px 0px 0px 14px;
      top: -5px;
      position: relative;
   }
    @media screen and (min-width:1025px) {
      font-size: 29px;
      line-height: 33px;
    }
   }
  .home-bar-block{
    .bar-chart{
      .more-about-country {
        text-align: center;
        text-decoration: underline;
        padding-top:5px;
        letter-spacing: 1.145px;
        cursor: pointer;
        @media (min-width: 768px) and (max-width: 1025px) {
          font-size: 12px;
          padding-top:10px;
        }
        overflow: hidden;
        background: linear-gradient(to right, #e86019, #e86019 50%, #000000 50%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #0000;
        background-size: 200% 100%;
        background-position: 100%;
        transition: background-position 300ms ease;
        text-decoration: none; // text decorations are clipped in WebKit browsers
        &:hover {
          background-position: 0 100%;
        }
        &:after {
          content: '';
          background: url('../images/forward\ arrow.svg');
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          width: 25px;
          height: 25px;
          padding-left: 5px;
        }
        &:hover::after {
          background: url('../images/hover-button-arrow.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
     }
  }
   
   

  .country-flag{
    @media screen and (min-width:768px) {
      display: flex;
      
    }
  }

  .country-name-home {
    overflow: hidden;
    background: linear-gradient(to right, #e86019, #e86019 50%, #000000 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #0000;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 300ms ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    &:hover {
      background-position: 0 100%;
    }
  }