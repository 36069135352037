.map-toggle-key {
  padding: 13px 10px 13px 10px;
  position:absolute;
  right:0;
  top:0;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.deaths {
    background-color: #FF5AE4;
    color: #fff;
}
.cases {
    background-color: #03BEE7;
}
.hide-element {
    display: none;
}

.map-key-desc {
    height: 50px;
    display: flex;
    flex-flow: wrap;
    background: #000000;
    margin-top: 40px;
    padding: 16px;
    box-shadow: 0px -10px 15px -8px rgba(255, 255, 255, 0.36);
    @media screen and (max-width:768px){
      display: none;
    }
    p {
        margin-bottom: 0;
        font-family: "Oswald";
        margin-right: 20px;
        line-height: 16px;
        color: #fff;
        font-size: 16px;
        @media (max-width: 768px) {
          display: none;
        }
    }
    .legend {
        display: flex;
        list-style: none;
        color: #fff;
        margin-right: 35px;
        margin-bottom: 0;
        padding: 0;
        @media screen and (max-width: 768px) {
          display: none;
        }
        li {
            margin-right: 25px;
            color: #fff;
            line-height: 18px;
        }
    }

    .key {
        vertical-align:top;
        margin-bottom: -2px;
        margin-right: 10px;
        width: 18px;
        height: 18px;
        display: inline-block;
      }
      .malaria-free {
        background-color: #B3E8ED;
      }
      .on-track {
        background-color: #78CF5B;
      }
      .near-track {
        background-color: #E9B44D;
      }
      .off-track {
        background-color: #D43225;
      }
}
