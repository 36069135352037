.cbyc-progress {
    margin-top: 30px;
    margin-bottom: 5px;
    @media screen and (min-width:768px) {
        margin-top: 60px;
        margin-bottom: 6px;
    }
}

.see-all {
    margin: 30px auto 40px;
}

.btn-toggle {
    color: #e86019;
    display: inline;
    font-family: 'Oswald', sans-serif;
    font-size: 31px;
    line-height: 34px;
    cursor: pointer;

    span {
        display: inline-block;
        width: 40px;
        height: 40px;
        bottom: -6px;
        right: -12px;
        position: relative;
        border-radius: 100%;
        background: url('../images/arrow.png');
        background-repeat: no-repeat;
        background-color: #e86019;
        background-position: center;
        background-size: 25px 25px;
    }

    &.hide-all {
        color: #a4a4a4;
        span {
            background-color: #a4a4a4;
            transform: rotate(180deg)
        }
    }

    &.show-all {
        span {
            ///transform: rotate(180deg)
        }
    }
}

.home-bar-block {
    padding-top: 20px;
    padding-bottom: 20px;
    .line-chart{
        .tick text{
            font-size: 12px;
            line-height: 18px;
            fill: #242424;
            font-style: "Oswald";
        }
    }
}



.home-bar-block {
    .line-chart svg {
        width: 100%;
    }
}

.home-footer {
    padding-bottom: 100px;
}

