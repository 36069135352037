.country-popup {
  svg {

    .label,
    .curr-year {
      fill: #fff;
    }

    .label {
      font-size: 14px;
      font-weight: 300;
    }

    .curr-year-box {
      fill: none;
    }

    .curr-line {
      stroke: #fff;
    }
  }
}

.country-popup {
  display: none;
  @media screen and (min-width:1025px) {
    //this is only visible on screens 1025 above
    display: inline-block;
  }

  background-color: #000;
  color: #fff;
  background: url("../images/TextureDark.jpg");
  background-position: 16% 65%;

  padding: 20px;
  width: 31%;
  position: absolute;
  right: 40%;
  top: 10%;

// TODO: make it generic
  &::after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #000;
    position: absolute;
    right: 105px;
    bottom: -20px;
    margin: 0 auto;
  }

  .line-chart{
    .axis-label{
      fill: #fff;
      font-size: 13px;
      font-style: "lato";
    }
    .selected-year-line {
      stroke-width: 0;
    }
    .axis-label .tick text {
      font-size: 12px;
      font-style: "Oswald";
    }
  }

  .close-popup {
    background: url("../images/close-orange.svg");
    display: inline-block;
    background-size: 100% 100%;
    height: 36px;
    width: 36px;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }

  .popup-header {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  .more-about-country {
    text-align: center;
    text-decoration: underline;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    span {
      font-size: 16px;
      cursor: pointer;
    }
    &:hover{
      color: #e86019;
    }
  }
}