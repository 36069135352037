.toggle-popup-hide {
  display: none;
}

.toggle-popup {
  display: none;
  @media screen and (min-width: 1025px) {
    //this is only visible on screens 1025 above
    display: inline-block;
  }
  @media screen and (min-width: 1400px) {
    right:3%;
    bottom: 16%;
    position: fixed;
    z-index: 2
  }
  color: #fff;
  width: 247px;
  height: 186px;
  position: absolute;
  right: 3%;

  .close-popup {
    background: url("../images/close-black.svg");
    display: inline-block;
    background-size: 100% 100%;
    height: 24px;
    width: 24px;
    position: absolute;
    top: -7px;
    right: -8px;
    cursor: pointer;
    z-index: 1;
  }

  .popup-header {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  .popup-body {
    mask-image: url("../images/header.png");
    mask-position: bottom;
    mask-repeat: no-repeat;
    mask-size: cover;
    padding: 27px 17px 71px 11px;
    background-color: #e86019;
    text-align: center;
    p{
      font-size: 25px;
      font-family: "Oswald";
      line-height: 24px;

    }
  }

  .more-about-country {
    span {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.mask-bottom-toggle {
  
}

.arrow-img {
  background: url("../images/arrow-popUp.png");
  display: inline-block;
  background-size: 100% 100%;
  height: 46px;
  width: 20px;
  position: absolute;
  top: 105px;
  left: 114px;
}
.stick-popup {
  position: fixed;
  bottom: 22%;
  z-index: 2;
  @media screen and (min-width:1400px){
    z-index: 2;
    top: 86%;
    position: fixed;
  }
}