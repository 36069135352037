.map-heading {
  font-size: 20px;
    margin: 15px 40px 0;
  @media screen and (min-width:768px) {
    font-size: 31px;
    margin-top: 20px;
    margin-bottom: 60px;
  }

}

.background {
  fill: none;
  pointer-events: all;
}

#states {
  stroke: #ebebeb;
  stroke-width: 0.01px;
}

#states path.states-class:hover{
  @media screen and (min-width:768px){
    fill-opacity: 0.62;
    cursor: pointer;
  }
}

#states text.active {
  font-size: 16px;
  font-weight:bold;
  fill: #fff;
  stroke-width: .5px;
  fill: rgb(167, 5, 5) !important;
  stroke: #000;
}

.popup-opened #states path{
  pointer-events: none;
}


/** how tracker works styles // TODO: move it to respective file*/
.tracker-work{
  -webkit-mask-image:none;
  mask-image:none;
  margin-bottom: 44px;
  .svg_border{
    width:16em;
    height: 2em;
    @media screen and (min-width:768px){
      width:25em;
    }
  }

  h3{
    font-size: 20px;
    @media screen and (min-width:768px){
      font-size: 31px;
    }
  }
}