
.map-legend {
  padding: 0 0;
  margin-top: 25px;
  margin-bottom: 10px;
  @media screen and (min-width: 780px) {
    padding: 0;
    margin:0;
  }

}

.map-toggle-key-mbl {
  position: absolute;
  right: 1px;
  top: 5px;
  padding: 14px;
  font-size: 21px;
}

.map-key {

  background: #000;
  background: url('../images/v1-legend.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  padding: 20px;
  width: 100%;
  z-index: 1;
  text-align: left;
  font-family: "Oswald",sans-serif;
  margin-top: 4px;
  @media screen and (min-width: 800px) {
    display: none;
    margin-top: 0;
  }

  .map-key-header {
    display: flex;
    .label {
      font-size: 20px;
      line-height: 1;
    }

    .toggle {
      display: flex;
      color: #FFFFFF;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      text-align: right;
      text-shadow: 3px 3px 9px 0 rgba(0,0,0,0.5);
      text-decoration: underline;
      text-underline-position: under;
      cursor: pointer;
      @media screen and (min-width: 780px) {
        display: none;
      }
    }

    span {
      width: 20px;
      height: 20px;
      margin-left: 9px;
      background: url("../images/downward.svg") no-repeat #e86019;
      background-size: 20px 20px;
      border-radius: 100%;
      &.up-arrows{
        transform: rotate(180deg);
      }
      transition: transform .2s ease-in;
    }
  }

  .map-key-body {
    margin-top: 12px;
    &.hidden {
      display: none;
    }
    @media screen and (max-width: 780px) {
      margin-top: 32px;
    }
  }

  h4 {
    font-family: 'Oswald';
    font-size: 16px;
    line-height: 16px;
    margin-top: 10px;
    font-weight: 400;
  }

  .key-list {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      font-size: 16px;
    }
  }
  .key {
    margin-right: 8px;
    margin-top: 5px;
    width: 18px;
    height: 18px;
  }
  .no-malaria {
    background-color: #C4EEC2;//#5EC2DC;
  }
  .on-track {
    background-color: #78CF5B;
  }
  .near-track {
    background-color: #E9B44D;
  }
  .off-track {
    background-color: #D43225;
  }
  .w-malaria {
    background-color: #B3B3B3;
  }
  .wo-malaria {
    background-color: #E3E3E3;
  }
}
