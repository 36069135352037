
.map-legend-mbl {
    padding: 0 0;
    @media screen and (min-width: 780px) {
      padding: 0;
    }
  
  }
  
  .map-toggle-key-mbl {
    position: absolute;
    right: 1px;
    top: 4px;
    padding: 14px;
    font-size: 21px;
  }
  
  .map-key-mbl {
  
    background: #000;
    background: url('../images/v1-legend.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    padding-right: 0;
    padding-left: 20px;
    padding-top: 0;
    width: 100%;
    z-index: 1;
    text-align: right;
    font-family: "Oswald",sans-serif;
    margin-top: 4px;
    @media screen and (min-width: 800px) {
      display: none;
      margin-top: 0;
      text-align: left;
      padding: 20px;
    }
  
    .map-key-header {
      display: flex;
      position:absolute;
      bottom: 29px;
      .label {
        font-size: 20px;
        line-height: 1;
      }
  
      .toggle {
        display: flex;
        color: #FFFFFF;
        font-weight: 300;
        font-size: 15px;
        line-height: 19px;
        text-align: right;
        text-shadow: 3px 3px 9px 0 rgba(0,0,0,0.5);
        text-decoration: underline;
        text-underline-position: under;
        cursor: pointer;
        @media screen and (min-width: 780px) {
          display: none;
        }
      }
  
      span {
        width: 20px;
        height: 20px;
        margin-left: 9px;
        background: url("../images/downward.svg") no-repeat #E86019;
        background-size: 21px 20px;
        border-radius: 100%;
        &.up-arrows{
          transform: rotate(180deg);
        }
        transition: transform .2s ease-in;
      }
    }
  
    .map-key-body {
      margin-top: 12px;
      &.hidden {
        display: none;
      }
      @media screen and (max-width: 780px) {
        margin-top: 0;
        padding-top: 20px;
      }
    }
  
    h4 {
      font-family: 'Lato', sans-serif;
      font-size: 17px;
      line-height: 23px;
      margin-top: 10px;
      font-weight: 400;
    }
  
    .key-list {
      list-style: none;
      padding: 0;
      li {
        display: flex;
        font-size: 17px;
      }
    }
    .key {
      margin-right: 5px;
      margin-top: 5px;
      width: 18px;
      height: 18px;
    }
    .no-malaria {
      background-color: #C4EEC2;//#5EC2DC;
    }
    .on-track {
      background-color: #78CF5B;
    }
    .near-track {
      background-color: #E9B44D;
    }
    .off-track {
      background-color: #D43225;
    }
    .w-malaria {
      background-color: #B3B3B3;
    }
    .wo-malaria {
      background-color: #E3E3E3;
    }
  }
  
  