


.country-selector {

    /** background texture */
    color: #fff;
    margin-top: 20px;
    @media screen and (min-width:1024px) {
        margin-bottom: 50px;
    }
    .row {
        background: url("../images/v1.jpg");
        padding: 20px;
        @media screen and (min-width:1024px) {
            margin-left: 40px;
            margin-right: 40px;
            padding: 15px;
        }
        @media screen and (min-width:1500px) {
            margin-top: 10%;
        }
    } 
    label {
        font-size: 15px;
        margin-bottom: 10px;
        min-width:55%;
        @media screen and (min-width:1024px) {
            font-size: 25px;
            margin-top: 10px;
            width:100%;
        }
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 281px;
      border-radius: 0;
      color: #FFF;
      font-size: 18px;
      height: 58px;
      padding: 14px;
      background: url("../images/dropdown.svg") 94% no-repeat #e86019;
      background-size: 32px 33px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      letter-spacing: 1.36px;
      line-height: 1.2;
      @media screen and (min-width:768px){
        width: 215px;
      }
      option {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: normal;
        display: block;
        white-space: pre;
        min-height: 1.2em;
        padding: 0px 2px 1px;
        background: #fff;
        color: #080808;
        &:disabled {
            background-color: #D3D3D3;
            color: #fff;
        }
      }
    }
    
}


// .select-country {
//     background: url("./images/TextureDark.jpg");
//     background-position: 160% 50%;
//     color: #fff;
//     font-size: 15px;
//     padding: 20px 40px;
//     margin-top: 25px;
//     text-align: center;
//     width: 100vw;

//     .o--mobile {
//       padding: 0px 45px;
//       display: inline-block;
//     }

//     .o--desktop {
//       display: none;
//     }

//     @media screen and (min-width:768px) {
//       font-size: 25px;
//       width: 82%;
//       z-index: 2;
//       margin: 0 auto;
//       margin-top: 20px;
//       padding: 10px 20px;

//       .o--desktop {
//         display: inline;
//       }

//       .o--mobile {
//         display: none;
//       }

//       p {
//         display: inline-block;
//         margin-right: 0%;
//         margin-bottom: 0px;
//         padding: 0px 8px;

//         select {
//           float: right;
//         }
//       }
//     }

//     @media screen and (min-width: 1025px) {
//       p {
//         margin-right: 6%;
//       }
//     }

//     select::-ms-expand {
//       display: none;
//     }

//     select {
//       background-color: #e86019;
//       border-radius: 0;
//       color: #FFF;
//       font-size: 18px;
//       width: 206px;
//       height: 50px;
//       padding: 14px;
//       background: url("./images/down.png") 94% no-repeat #e86019;
//       background-size: 32px 33px;
//       outline: none;
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       appearance: none;
//       border: 0;
//       letter-spacing: 1.36px;
//       line-height: 1.2;

//       @media (min-width: 768px) and (max-width: 1025px) {
//         margin-top: 10px;
//       }

//       @media screen and (min-width:768px) {
//         padding: 0px 14px;
//       }
//     }
//   }